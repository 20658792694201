// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  z-index: 998;
  left: 0;
}
@media (prefers-color-scheme: light) {
  .spinner-wrapper {
    background-color: rgba(255, 255, 255, 0.5);
  }
}
@media (prefers-color-scheme: dark) {
  .spinner-wrapper {
    --ion-background-color: #1c1242;
    background: #001529;
  }
}
.spinner-wrapper app-spinner {
  width: 6rem;
  height: 6rem;
}`, "",{"version":3,"sources":["webpack://./src/app/utils/spinner-overlay/spinner-overlay.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,MAAA;EACA,YAAA;EACA,OAAA;AACF;AAAE;EAVF;IAWI,0CAAA;EAGF;AACF;AAAE;EAfF;IAgBI,+BAAA;IACA,mBAAA;EAGF;AACF;AADE;EACE,WAAA;EACA,YAAA;AAGJ","sourcesContent":[".spinner-wrapper {\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  top: 0;\n  z-index: 998;\n  left: 0;\n  @media (prefers-color-scheme: light){\n    background-color: rgba(255, 255, 255, 0.5);\n  }\n\n\n  @media (prefers-color-scheme: dark){\n    --ion-background-color: #1c1242;\n    background: #001529;\n  }\n\n  app-spinner {\n    width: 6rem;\n    height: 6rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
