import {Component, Input, OnInit, Output} from '@angular/core';
import {Location} from "../../../../models/business/location";
import {OverlayService} from "../../../../services/overlay.service";
import {RestService} from "../../../../services/rest.service";
import {Router} from "@angular/router";
import {NzModalService} from "ng-zorro-antd/modal";
import {NzMessageService} from "ng-zorro-antd/message";
import {AuthService} from "../../../../services/auth.service";
import {StringGenerator} from "../../../../utils/string-generator.utils";
import {Company} from "../../../../models/business/company";

@Component({
  selector: 'app-new-location',
  templateUrl: './new-location.component.html',
  styleUrls: ['./new-location.component.scss'],
})
export class NewLocationComponent implements OnInit {

  @Input() isModal: boolean = false;

  @Output() newLocationTemplate: Location = new Location();
  companies: Company[] = [];
  constructor(
    private overlay: OverlayService,
    private rest: RestService,
    private router: Router,
    private modal: NzModalService,
    private message: NzMessageService,
    private auth: AuthService
  ) {
    this.overlay.hide();

  }

  goBack() {

  }

  ngOnInit() {}


  submitForm() {

    this.newLocationTemplate.id = new StringGenerator(9).generateString(9) || '';
    this.newLocationTemplate.tenant = this.auth.currentUserValue.tenant.id;

    this.rest.post('business/locations/', this.newLocationTemplate)
      .subscribe(
        (res)=>{
          this.message.success("Created new company successfully!");
          if(this.isModal){
            this.modal.closeAll();
          }
        },
        (err) => {
          this.message.error("An error occcured while saving your company.")
          console.log(err);
        }
      )

  }


  loadCompanies() {
    this.rest.get('business/companies/')
      .subscribe((res: Company[]) =>{
        this.companies = res;
      })
  }
}
