import {Component, Input, OnInit} from '@angular/core';
import {Asset} from "../../models/assets/asset";
import {Status} from "../../models/assets/status";
import {RestService} from "../../services/rest.service";
import {Category} from "../../models/assets/category";
import {Location} from "../../models/business/location";
import {Company} from "../../models/business/company";

@Component({
  selector: 'app-asset-list-template',
  templateUrl: './asset-list-template.component.html',
  styleUrls: ['./asset-list-template.component.scss'],
})
export class AssetListTemplateComponent implements OnInit {
  @Input() assetList: Asset[] = [];
  trackByIndex(_: number, data: Asset): string {
    return data.id;
  }

  statuses: Status[] = [];
  categories: Category[] = [];
  locations: Location[] = [];
  companies: Company[] = [];
  loaded: boolean = false;

  constructor(
    private rest: RestService
  ) { }

  ngOnInit() {
    this.rest.get('assets/statuses/')
      .subscribe(
        (res2: Status[]) => {
          this.statuses = res2;
          this.loaded = true;
        }
      )
    this.rest.get('assets/categories/')
      .subscribe(
        (res2: Category[]) => {
          this.categories = res2;
        }
      )
    this.rest.get('business/locations/')
      .subscribe(
        (res2: Location[]) => {
          this.locations = res2;
        }
      )
    this.rest.get('business/companies/')
      .subscribe(
        (res2: Company[]) => {
          this.companies = res2;
        }
      )
  }

  getStatusData(status: string): Status | any{
    for (let i = 0; i < this.statuses.length; i++) {
      if(this.statuses[i].id === status){
        return this.statuses[i];
      }
    }
    return null;
  }

  getLocationData(location: string): Location | any {
    for (let i = 0; i < this.locations.length; i++) {
      if(this.locations[i].id === location){
        return this.locations[i];
      }
    }
    return null;
  }

  getCategoryData(category: string): Category | any{
    for (let i = 0; i < this.categories.length; i++) {
      if(this.categories[i].id === category){
        return this.categories[i];
      }
    }
    return null;
  }
  getCompanyData(company: string): Company | any{
    for (let i = 0; i < this.companies.length; i++) {
      if(this.companies[i].id === company){
        return this.companies[i];
      }
    }
    return null;
  }

}
