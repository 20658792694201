import {Component, Input, OnInit} from '@angular/core';
import {Log} from "../../models/assets/log";

@Component({
  selector: 'app-asset-log-template',
  templateUrl: './asset-log-template.component.html',
  styleUrls: ['./asset-log-template.component.scss'],
})
export class AssetLogTemplateComponent implements OnInit {

  @Input() log: Log;

  logTypes: any = {
    "n": {"display": "General Note", "icon": "info"},
    "m": {"display": "Maintenance", "icon": "tool"},
    "z": {"display": "Management", "icon": "wallet"},
    "a": {"display": "Audit Record", "icon": "audit"},
    "c": {"display": "Check-out", "icon": "swap"}
  };

  constructor() { }

  ngOnInit() {}

}
