import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {RestService} from "../services/rest.service";
import {AuthService} from "../services/auth.service";
import {OverlayService} from "../services/overlay.service";
import {environment} from "../../environments/environment";

@Component({
  selector: 'app-connect',
  templateUrl: './connect.component.html',
  styleUrls: ['./connect.component.scss'],
})
export class ConnectComponent implements OnInit {
  param1 = '';
  refreshFunction: any;
  title = 'Communicating with api..';
  amountOfIncompleteEmployeeForms: number = 0;
  amountOfIncompleteProjectForms: number = 0;

  constructor(
    private route: ActivatedRoute,
    public rest: RestService,
    public auth: AuthService,
    private router: Router,
    private overlay: OverlayService
  ) {
    console.log("Running connect...")
    this.route.queryParams.subscribe((params) => {
      this.param1 = params.nonce;
      console.log('Initiating discussion with backend...', this.param1);
      this.auth.loginConnect(this.param1).subscribe(
        (res) => {
          if (res.access) {
            const username = res.username;
            console.log(username);
            this.overlay.hide();
            this.router.navigate(['/']);
          }
        },
        (err) => {
          console.log("Connect Failed, retrying...")
          console.log(err);
          window.location.href = environment.loginUrl+this.auth.tenantSubdomain+'/';
        }
      );
    });
  }

  ngOnInit() {}

}
