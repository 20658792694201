import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

/* eslint-disable @typescript-eslint/naming-convention */
@Injectable({
  providedIn: 'root',
})
export class RestService {
  public isLoggedIn = false;
  protected tenant: string = '';

  constructor(private http: HttpClient, private auth: AuthService) {
    this.checkTenant();
  }

  post(uri: string, data: any): Observable<any> {
    const headers: any = {
      Authorization: 'Bearer ' + this.auth.currentUserValue.access,
      'x-bullpen-tenant': this.tenant,
    };
    return this.http.post(environment.baseUrl + uri, data, headers);
  }

  patch(uri: string, data: any): Observable<any> {
    const headers: any = {
      Authorization: 'Bearer ' + this.auth.currentUserValue.access,
      'x-bullpen-tenant': this.tenant,
    };
    return this.http.patch(environment.baseUrl + uri, data, headers);
  }

  get(uri: string): Observable<any> {
    const headers: any = {
      Authorization: 'Bearer ' + this.auth.currentUserValue.access,
      'x-bullpen-tenant': this.tenant,
    };
    return this.http.get(environment.baseUrl + uri, headers);
  }

  delete(uri: string): Observable<any> {
    const headers: any = {
      Authorization: 'Bearer ' + this.auth.currentUserValue.access,
      'x-bullpen-tenant': this.tenant,
    };
    return this.http.delete(environment.baseUrl + uri, headers);
  }

  private checkTenant() {
    var req = new XMLHttpRequest();
    req.open('GET', document.location.href, false);
    req.send(null);
    var tenant = req.getResponseHeader('x-fastasset-tenant');
    if (tenant === null && environment.production) {
      window.location.href = 'https://my.jouleworksdev.com/';
    }
    if (!environment.production) {
      this.tenant = tenant || 'dev';
    } else {
      this.tenant = tenant;
    }

    this.auth.tenantSubdomain = this.tenant;

    console.log(this.tenant);
  }
}
