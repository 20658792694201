import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HomePage} from "./home/home.page";
import {NzLayoutModule} from "ng-zorro-antd/layout";
import {NzMenuModule} from "ng-zorro-antd/menu";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzBreadCrumbModule} from "ng-zorro-antd/breadcrumb";
import { IconDefinition } from '@ant-design/icons-angular';
import { UserOutline, BuildOutline, ToolOutline, DashboardOutline, PlusOutline, FileTextOutline, SwapOutline, MoreOutline, FilterOutline, InfoOutline, WalletOutline, AuditOutline, ArrowLeftOutline } from '@ant-design/icons-angular/icons';
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {NzGridModule} from "ng-zorro-antd/grid";
import {NzCardModule} from "ng-zorro-antd/card";
import {NzTableModule} from "ng-zorro-antd/table";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {NzSpinModule} from "ng-zorro-antd/spin";
import {NzSkeletonModule} from "ng-zorro-antd/skeleton";
import {JwtInterceptor} from "./interceptors/jwt.interceptor";
import {ErrorInterceptor} from "./interceptors/error.interceptor";
import {SpinnerOverlayComponent} from "./utils/spinner-overlay/spinner-overlay.component";
import {ConnectComponent} from "./connect/connect.component";
import {AssetListComponent} from "./interfaces/asset/asset-list/asset-list.component";
import {SettingsGeneralComponent} from "./interfaces/settings/settings-general/settings-general.component";
import {AssetListTemplateComponent} from "./reusables/asset-list-template/asset-list-template.component";
import {AssetNewComponent} from "./interfaces/asset/asset-new/asset-new.component";
import {NzPageHeaderModule} from "ng-zorro-antd/page-header";
import {NzSpaceModule} from "ng-zorro-antd/space";
import {NzButtonModule} from "ng-zorro-antd/button";
import {AssetDetailComponent} from "./interfaces/asset/asset-detail/asset-detail.component";
import {AssetLogTemplateComponent} from "./reusables/asset-log-template/asset-log-template.component";
import {
  MaintenanceTrackListComponent
} from "./interfaces/maintenance/maintenance-track-list/maintenance-track-list.component";
import {NzModalModule} from "ng-zorro-antd/modal";
import {
  StatusDisplayTemplateComponent
} from "./reusables/status-display-template/status-display-template.component";
import {NzDropDownModule} from "ng-zorro-antd/dropdown";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzCheckboxModule} from "ng-zorro-antd/checkbox";
import {NzInputNumberModule} from "ng-zorro-antd/input-number";
import {NzSelectModule} from "ng-zorro-antd/select";
import {NewCategoryComponent} from "./interfaces/asset/categories/new-category/new-category.component";
import {NzDrawerModule} from "ng-zorro-antd/drawer";
import {NzMessageModule} from "ng-zorro-antd/message";
import {NewCompanyComponent} from "./interfaces/business/company/new-company/new-company.component";
import {NewLocationComponent} from "./interfaces/business/location/new-location/new-location.component";
import {NewStatusComponent} from "./interfaces/asset/statuses/new-status/new-status.component";

registerLocaleData(en);

const icon: IconDefinition[] = [UserOutline, BuildOutline, ToolOutline, DashboardOutline, PlusOutline, FileTextOutline, SwapOutline, MoreOutline, FilterOutline, InfoOutline, WalletOutline, AuditOutline, ArrowLeftOutline];

@NgModule({
  declarations: [
    AppComponent,
    HomePage,
    SpinnerOverlayComponent,
    ConnectComponent,
    AssetListComponent,
    SettingsGeneralComponent,
    AssetListTemplateComponent,
    AssetNewComponent,
    AssetDetailComponent,
    AssetLogTemplateComponent,
    MaintenanceTrackListComponent,
    StatusDisplayTemplateComponent,
    NewCategoryComponent,
    NewCompanyComponent,
    NewLocationComponent,
    NewStatusComponent
  ],
  imports: [BrowserModule,
    IonicModule.forRoot({mode: 'ios'}),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    NzLayoutModule,
    NzMenuModule,
    NzIconModule.forRoot(icon),
    NzBreadCrumbModule,
    NzToolTipModule,
    NzGridModule,
    NzCardModule,
    NzTableModule,
    NzDividerModule,
    NzSpinModule,
    NzSkeletonModule,
    NzPageHeaderModule,
    NzSpaceModule,
    NzButtonModule,
    NzModalModule,
    NzDropDownModule,
    NzInputModule,
    NzFormModule,
    ReactiveFormsModule, NzCheckboxModule, NzInputNumberModule, NzSelectModule,
    NzDrawerModule,
    NzMessageModule
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
