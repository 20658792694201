export class Asset {
  tenant: string = '';
  id: string = '';
  readable_serial?: string = '';
  company?: any = '';
  supplier_company?: any = '';
  category?: any = '';
  name: string = '';
  supplier_serial_number?: string = '';
  valuation?: string = '';
  location?: any = '';
  checked_out?: any = '';
  status?: any = '';
  default_maintenance_track?: any = '';
  readonly serial_number: string;
}
