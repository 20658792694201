import { Component, OnInit } from '@angular/core';
import {OverlayService} from "../../../services/overlay.service";
import {RestService} from "../../../services/rest.service";
import {Asset} from "../../../models/assets/asset";
import {Status} from "../../../models/assets/status";

@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss'],
})
export class AssetListComponent implements OnInit {
  loaded: boolean = false;

  assetList: Asset[] = [];

  constructor(
    private overlay: OverlayService,
    private rest: RestService
  ) {
    this.overlay.hide();

  }

  ngOnInit() {
    this.rest.get('assets/assets/?depth=1')
      .subscribe(
        (res: Asset[]) => {
          console.log(res.length);
          this.assetList = res;
          this.loaded = true;
        }
      )
  }

}
