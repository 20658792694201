import { Component, OnInit } from '@angular/core';
import {OverlayService} from "../../../services/overlay.service";
import {RestService} from "../../../services/rest.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-settings-general',
  templateUrl: './settings-general.component.html',
  styleUrls: ['./settings-general.component.scss'],
})
export class SettingsGeneralComponent implements OnInit {

  constructor(
    private overlay: OverlayService,
    private rest: RestService,
    private router: Router
  ) {
    this.overlay.hide();

  }

  ngOnInit() {}

  goBack() {

  }
}
