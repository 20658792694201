import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {OverlayService} from "./services/overlay.service";
import {AuthService} from "./services/auth.service";
import {environment} from "../environments/environment";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent {
  isCollapsed: boolean = true;
  param1: string = '';
  activeLogin: boolean = true;
  constructor(
    public router: Router,
    private overlay: OverlayService,
    public auth: AuthService,
    private route: ActivatedRoute,
  ) {
    console.log("3Running connect...")
    if(window.location.href.indexOf('/connect?') !== -1 && this.auth.currentUserValue === null && this.activeLogin){} {

    }
    this.overlay.show('Loading');
  }

  quickMove(s: string) {
    if(this.isCollapsed){
      this.router.navigate([s]);
    }
  }
}
