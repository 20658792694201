import { Component } from '@angular/core';
import {Router} from "@angular/router";
import {OverlayService} from "../services/overlay.service";
import {AuthService} from "../services/auth.service";
import {RestService} from "../services/rest.service";

class DashboardFuncs {
  total_assets_tracked: string = "";
  assets_checked_out: string = "";
  assets_requiring_checkout: string = "";
  maintenance_due_soon: string = "";
  assets_under_repair: any[] = [];
  assets_out_of_system: any[] = [];
}

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {
  isCollapsed: boolean = false;
  dataSet: any = [];
  loaded: boolean = false;

  dashboardFunctions: DashboardFuncs = new DashboardFuncs();

  constructor(
    public router: Router,
    public overlay: OverlayService,
    public auth: AuthService,
    private rest: RestService
  ) {

    this.rest.get('functions/dashboard/')
      .subscribe(
        (res: DashboardFuncs) => {
          this.overlay.hide();
          this.dashboardFunctions = res;
          this.loaded = true;
        },
        (err) => {
          //console.log(err);
        }
      )

  }

  sendToPage(s: string) {

  }
}
