import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { Membership } from '../models/membership';
import {OverlayService} from "./overlay.service";
import {log} from "ng-zorro-antd/core/logger";

@Injectable({ providedIn: 'root' })
export class AuthService {
  public currentUser: Observable<User>;
  private currentUserSubject: BehaviorSubject<User>;
  public tenantSubdomain: string = 'dev';
  public isLoggedIn: boolean = false;
  public backend: string = environment.backendDomain;
  public userHasMultipleTenants: boolean = false;
  constructor(private http: HttpClient, private router: Router, private overlay: OverlayService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentFAUser')));
    this.currentUser = this.currentUserSubject.asObservable();

    console.log("Checking Tenant...")
    var req = new XMLHttpRequest();
    req.open('GET', '/', false);
    req.send(null);
    var tenant = req.getResponseHeader('x-fastasset-tenant');
    console.log(tenant);
    if (tenant === null && environment.production) {
      console.log("This is production.");
      window.location.href = 'https://my.jouleworksdev.com/';
    }
    if (!environment.production) {
      console.log("This is development.");
      this.tenantSubdomain = 'dev';
    } else {
      this.tenantSubdomain = tenant;
    }

    console.log(window.location.href);

      if(this.currentUserSubject.value !== null){
        this.isLoggedIn = true;
      }else{
        if(window.location.href.indexOf('/connect?') === -1){
          this.logout();
        }else{
          console.log("Waiting for login...");
        }
      }


    this.checkUserHasMultipleTenants();
  }

  public checkUserHasMultipleTenants() {
    if (this.currentUserValue !== null) {
      if (this.currentUserValue.tenant.length > 1) {
        this.userHasMultipleTenants = true;
      }
    }

    this.overlay.hide();

  }

  public get tenant(): string {
    return this.tenantSubdomain;
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *
   * For later use, allows login from username/password entry to be fetched.
   *
   * @param username
   * @param password
   */

  getTenant() {}

  reloadPermissions() {
    let t: User = JSON.parse(localStorage.getItem('currentFAUser'));
    this.http
      .get(`${environment.tenantUrl}permissions/reload`, {
        headers: { Authorization: 'Bearer ' + this.currentUserValue.access },
      })
      .subscribe((res: any) => {
        t.membership = res.membership as Membership;
      });
  }

  /**
   * Utilizes OTP login and fetch
   * @param otp
   */

  loginOauth(otp: string) {
    return this.http.get<any>(`${environment.baseUrl}app/oidc/fetch/${otp}/`).pipe(
      map((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentFAUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.router.navigate(['/']);
        return user;
      })
    );
  }

  loginConnect(otp: string) {
    return this.http.get<any>(`${environment.tenantUrl}nonce/${otp}/`).pipe(
      map((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentFAUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.checkUserHasMultipleTenants();
        this.router.navigate(['/']);
        return user;
      })
    );
  }

  logout(onPurpose: boolean = false) {
    // remove user from local storage to log user out
    localStorage.removeItem('currentFAUser');
    this.currentUserSubject.next(null);
    if (onPurpose) {
      window.location.href = "https://" + environment.backendDomain + '/connect/logout/';
    }else{
      location.href = environment.loginUrl+this.tenant+'/'
    }
    //this.router.navigate(['/login']);
  }
}
