import {Component, Input, OnInit, Output} from '@angular/core';
import {Status} from "../../../../models/assets/status";
import {OverlayService} from "../../../../services/overlay.service";
import {RestService} from "../../../../services/rest.service";
import {Router} from "@angular/router";
import {NzModalService} from "ng-zorro-antd/modal";
import {NzMessageService} from "ng-zorro-antd/message";
import {AuthService} from "../../../../services/auth.service";
import {StringGenerator} from "../../../../utils/string-generator.utils";

@Component({
  selector: 'app-new-status',
  templateUrl: './new-status.component.html',
  styleUrls: ['./new-status.component.scss'],
})
export class NewStatusComponent implements OnInit {

  @Input() isModal: boolean = false;

  @Output() newLocationTemplate: Status = new Status();
  statuses: Status[] = [];
  constructor(
    private overlay: OverlayService,
    private rest: RestService,
    private router: Router,
    private modal: NzModalService,
    private message: NzMessageService,
    private auth: AuthService
  ) {
    this.overlay.hide();

  }

  goBack() {

  }

  ngOnInit() {}


  submitForm() {

    this.newLocationTemplate.id = new StringGenerator(9).generateString(9) || '';
    this.newLocationTemplate.tenant = this.auth.currentUserValue.tenant.id;

    this.rest.post('assets/statuses/', this.newLocationTemplate)
      .subscribe(
        (res)=>{
          this.message.success("Created new disposition successfully!");
          if(this.isModal){
            this.modal.closeAll();
          }
        },
        (err) => {
          this.message.error("An error occcured while saving your disposition.")
          console.log(err);
        }
      )

  }


  loadStatuses() {
    this.rest.get('assets/statuses/?depth=2')
      .subscribe((res: Status[]) =>{
        this.statuses = res;
      })
  }

}
