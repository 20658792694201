import {Component, Input, OnInit, Output} from '@angular/core';
import {OverlayService} from "../../../../services/overlay.service";
import {Router} from "@angular/router";
import {RestService} from "../../../../services/rest.service";
import {Category} from "../../../../models/assets/category";
import {NzModalService} from "ng-zorro-antd/modal";
import {NzMessageService} from "ng-zorro-antd/message";
import {AuthService} from "../../../../services/auth.service";
import {StringGenerator} from "../../../../utils/string-generator.utils";

@Component({
  selector: 'app-new-category',
  templateUrl: './new-category.component.html',
  styleUrls: ['./new-category.component.scss'],
})
export class NewCategoryComponent implements OnInit {

  @Input() isModal: boolean = false;

  @Output() newCategoryTemplate: Category = new Category();

  constructor(
    private overlay: OverlayService,
    private rest: RestService,
    private router: Router,
    private modal: NzModalService,
    private message: NzMessageService,
    private auth: AuthService
  ) {
    this.overlay.hide();

  }

  ngOnInit() {}

  goBack() {

  }

  submitForm() {

    this.newCategoryTemplate.id = new StringGenerator(9).generateString(9) || '';
    this.newCategoryTemplate.tenant = this.auth.currentUserValue.tenant.id;

    this.rest.post('assets/categories/', this.newCategoryTemplate)
      .subscribe(
        (res)=>{
          this.message.success("Created new category successfully!");
          if(this.isModal){
            this.modal.closeAll();
          }
        },
        (err) => {
          this.message.error("An error occcured while saving your category.")
          console.log(err);
        }
      )

  }
}
