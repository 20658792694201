import {Component, Input, OnInit} from '@angular/core';
import {Asset} from "../../models/assets/asset";

@Component({
  selector: 'app-status-display-template',
  templateUrl: './status-display-template.component.html',
  styleUrls: ['./status-display-template.component.scss'],
})
export class StatusDisplayTemplateComponent implements OnInit {

  groupingColors: any = {
    green: '#14bf1c',
    blue: '#1c49c1',
    red: '#c11c1c',
    black: '#040404',
    orange: '#cc6200',
  }

  @Input() isListEntry: boolean = false;

  @Input() asset: Asset;

  constructor() {
    console.log(this.asset);
  }

  ngOnInit() {}

}
