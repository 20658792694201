import { Component, OnInit } from '@angular/core';
import {OverlayService} from "../../../services/overlay.service";
import {RestService} from "../../../services/rest.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Asset} from "../../../models/assets/asset";
import {Log} from "../../../models/assets/log";

@Component({
  selector: 'app-asset-detail',
  templateUrl: './asset-detail.component.html',
  styleUrls: ['./asset-detail.component.scss'],
})
export class AssetDetailComponent implements OnInit {

  constructor(
    private overlay: OverlayService,
    private rest: RestService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  assetData: Asset;
  loaded: boolean = false;
  logs: Log[] = [];
  logsLoaded: boolean = false;
  isRecordModalVisible: boolean = false;

  selectedFilters: any = [
    'g','m','z','a','c'
  ]
  isRecordModalLoading: boolean = false;
  newNoteDetail: string = '';


  ngOnInit() {

    let s = this.route.snapshot.paramMap.get("id");
    this.rest.get('assets/assets/'+s+'/?depth=2')
      .subscribe(
        (res) => {
          console.log(res);
          this.assetData = res;
          this.loaded = true;
          this.overlay.hide();
        }
      )

    this.rest.get('assets/logs/?depth=2&asset='+s)
      .subscribe(
        (res: Log[]) => {
          this.logs = res;
          this.logsLoaded = true;
          console.log(this.logs);
        }
      )


  }

  loadLogs() {
    this.rest.get('assets/logs/?depth=2&asset='+this.assetData.id)
      .subscribe(
        (res: Log[]) => {
          this.logs = res;
          this.logsLoaded = true;
        }
      )
  }

  handleRecordModalOk() {
    this.isRecordModalLoading = true;

    let newLog: Log = {
      asset: this.assetData.id,
      body: this.newNoteDetail,
      tenant: this.assetData.tenant
    } as Log;

    this.rest.post('assets/logs/?depth=0', newLog)
      .subscribe(
        (res: Log) => {
          this.loadLogs();
          this.isRecordModalLoading = false;
          this.isRecordModalVisible = false;
        },
        (err) => {
          console.log(err);
          this.isRecordModalLoading = false;
        }
      )
  }

  findFilter(general: string): string {
    for (let i = 0; i < this.selectedFilters.length; i++) {
      if(this.selectedFilters[i] === general){
        return 'primary';
      }
    }
    return 'secondary';
  }

  toggleFilter(general: string) {
    for (let i = 0; i < this.selectedFilters.length; i++) {
      if(this.selectedFilters[i] === general){
        this.selectedFilters.splice(i,1);
        return null;
      }
    }
    this.selectedFilters.push(general);
    return null;
  }
}
