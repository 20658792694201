import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {HomePage} from "./home/home.page";
import {ConnectComponent} from "./connect/connect.component";
import {AssetListComponent} from "./interfaces/asset/asset-list/asset-list.component";
import {SettingsGeneralComponent} from "./interfaces/settings/settings-general/settings-general.component";
import {AssetDetailComponent} from "./interfaces/asset/asset-detail/asset-detail.component";
import {
  MaintenanceTrackListComponent
} from "./interfaces/maintenance/maintenance-track-list/maintenance-track-list.component";
import {AssetNewComponent} from "./interfaces/asset/asset-new/asset-new.component";

export const routes: Routes = [
  {
    path: '',
    component: HomePage
  },
  {
    path: 'connect',
    component: ConnectComponent
  },
  {
    path: 'assets/list',
    component: AssetListComponent
  },
  {
    path: 'assets/new',
    component: AssetNewComponent
  },
  {
    path: 'assets/detail/:id',
    component: AssetDetailComponent
  },
  {
    path: 'settings',
    component: SettingsGeneralComponent
  },
  {
    path: 'maintenance/tracks',
    component: MaintenanceTrackListComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
