import {Component, OnInit, ViewContainerRef} from '@angular/core';
import {OverlayService} from "../../../services/overlay.service";
import {RestService} from "../../../services/rest.service";
import {Router} from "@angular/router";
import {Asset} from "../../../models/assets/asset";
import {Category} from "../../../models/assets/category";
import {NzDrawerService} from "ng-zorro-antd/drawer";
import {NewCategoryComponent} from "../categories/new-category/new-category.component";
import {NzModalService} from "ng-zorro-antd/modal";
import {NzMessageService} from "ng-zorro-antd/message";
import {NewCompanyComponent} from "../../business/company/new-company/new-company.component";
import {Company} from "../../../models/business/company";
import {AuthService} from "../../../services/auth.service";
import {Location} from "../../../models/business/location";
import {NewLocationComponent} from "../../business/location/new-location/new-location.component";
import {Status} from "../../../models/assets/status";
import {NewStatusComponent} from "../statuses/new-status/new-status.component";
import {StringGenerator} from "../../../utils/string-generator.utils";

@Component({
  selector: 'app-asset-new',
  templateUrl: './asset-new.component.html',
  styleUrls: ['./asset-new.component.scss'],
})
export class AssetNewComponent implements OnInit {

  constructor(
    private overlay: OverlayService,
    private rest: RestService,
    private router: Router,
    private drawerService: NzDrawerService,
    private modal: NzModalService,
    private viewContainerRef: ViewContainerRef,
    private message: NzMessageService,
    private auth: AuthService
  ) {
    this.overlay.hide();

  }


  openCategoryComponent(): void {
    const modal = this.modal.create({
      nzTitle: 'New Category',
      nzContent: NewCategoryComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        isModal: true
      },
      nzFooter: null
    });
    const instance = modal.getContentComponent();
    modal.afterOpen.subscribe(() => console.log('[afterOpen] emitted!'));
    // Return a result when closed
    modal.afterClose.subscribe(result => this.loadCategories());

  }

  openCompanyComponent(): void {
    const modal = this.modal.create({
      nzTitle: 'New Company',
      nzContent: NewCompanyComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        isModal: true
      },
      nzFooter: null
    });
    const instance = modal.getContentComponent();
    modal.afterOpen.subscribe(() => console.log('[afterOpen] emitted!'));
    // Return a result when closed
    modal.afterClose.subscribe(result => this.loadCompanies());

  }

  openLocationsComponent(): void {
    const modal = this.modal.create({
      nzTitle: 'New Location',
      nzContent: NewLocationComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        isModal: true
      },
      nzFooter: null
    });
    const instance = modal.getContentComponent();
    modal.afterOpen.subscribe(() => console.log('[afterOpen] emitted!'));
    // Return a result when closed
    modal.afterClose.subscribe(result => this.loadLocations());

  }

  ngOnInit() {

  }

  loadCategories() {
    this.rest.get('assets/categories/')
      .subscribe((res: Category[]) =>{
        this.categories = res;
      })
  }

  loadCompanies() {
    this.rest.get('business/companies/')
      .subscribe((res: Company[]) =>{
        this.companies = res;
      })
  }

  loadLocations() {
    this.rest.get('business/locations/?depth=2')
      .subscribe((res: Location[]) =>{
        this.locations = res;
      })
  }

  loadDispositions() {
    this.rest.get('assets/statuses/?depth=2')
      .subscribe((res: Status[]) =>{
        this.statuses = res;
      })
  }

  newAssetTemplate: Asset = new Asset();
  formatterDollar = (value: number): string => `${(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD'
  })}`;
  parserDollar = (value: string): string => value.replace('$', '').replace(',','');
  categories: Category[] = [];
  companies: Company[] = [];
  informationRegardingSettings: string = '';
  locations: Location[] = [];
  statuses: Status[] = [];


  goBack() {
    this.router.navigate(['/assets/list'])
  }

  submitForm() {
    this.newAssetTemplate.id = new StringGenerator(9).generateString(9) || '';
    this.newAssetTemplate.tenant = this.auth.currentUserValue.tenant.id;
    this.rest.post('assets/assets/', this.newAssetTemplate)
      .subscribe(
        {
          next: (r) => {
            this.message.success("Created new asset successfully!");
            this.router.navigate(['/assets/list']);
          },
          error: (e) => {
            console.log(e);
            this.message.error("There was an issue saving your asset.");
          }
        }
      )
  }

  generatedAssetNum() {
    let n: string = this.auth.currentUserValue.membership.tenant.settings.asset_tag_template || '';
    if(this.newAssetTemplate.category === '' || this.newAssetTemplate.company === ''){
      return '';
    }

    //$assetType$number$companyCode

    let num: number = this.auth.currentUserValue.membership.tenant.settings.number_of_padded_zeroes | 1;

    let serial = n.replace('$assetType', this.newAssetTemplate.category.shortCode).replace('$companyCode', this.newAssetTemplate.company.shortCode).replace('$number', '*'.repeat(num));

    if(this.auth.currentUserValue.membership.tenant.settings.use_incremental){
      this.informationRegardingSettings = 'The number will be next-available incremental number with '+num+' appended zeroes at-time of saving based on the settings. The style of your asset tag can be controlled in settings.';
    }else{
      this.informationRegardingSettings = 'The number will be randomized with '+num+' appended zeroes based on your settings. The style of your asset tag can be controlled in settings.';
    }

    return serial;
  }

  addCategory(inputElement: HTMLInputElement) {

  }

  openDispositionsComponent() {
    const modal = this.modal.create({
      nzTitle: 'New Disposition',
      nzContent: NewStatusComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {
        isModal: true
      },
      nzFooter: null
    });
    const instance = modal.getContentComponent();
    modal.afterOpen.subscribe(() => console.log('[afterOpen] emitted!'));
    // Return a result when closed
    modal.afterClose.subscribe(result => this.loadDispositions());
  }
}
