import { Component, OnInit } from '@angular/core';
import {MaintenanceTrack} from "../../../models/assets/maintenance-track";
import {OverlayService} from "../../../services/overlay.service";
import {RestService} from "../../../services/rest.service";
import {AuthService} from "../../../services/auth.service";

@Component({
  selector: 'app-maintenance-track-list',
  templateUrl: './maintenance-track-list.component.html',
  styleUrls: ['./maintenance-track-list.component.scss'],
})
export class MaintenanceTrackListComponent implements OnInit {
  loaded: boolean = false;

  trackList: MaintenanceTrack[] = [];
  isVisible: boolean = false;
  templateTrack: any = {
    name: '',
  };


  constructor(
    private overlay: OverlayService,
    private rest: RestService,
    private auth: AuthService
  ) {
    this.overlay.hide();

  }

  trackByIndex(_: number, data: MaintenanceTrack): string {
    return data.id;
  }

  ngOnInit() {
    this.rest.get('assets/maintenance/tracks/?depth=1')
      .subscribe(
        (res: MaintenanceTrack[]) => {
          console.log(res.length);
          this.trackList = res;
          this.loaded = true;
        }
      )
  }

  handleCancel() {
    this.isVisible = false;
  }

  handleOk() {
    this.rest.post('assets/maintenance/tracks/?depth=0', this.templateTrack)
      .subscribe(
        (res: MaintenanceTrack) =>{
          this.ngOnInit();
          this.isVisible = false;
        }
      )

  }
}
