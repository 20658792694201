
export class StringGenerator {

  characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  len: number = 64;
  constructor(
    private length: number = 64
  ) {
    this.len = length;
  }

  generateString(length = this.len): string {
    let result = ' ';
    const charactersLength = this.characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += this.characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
}
